import Layout from "../components/layout"
import Hero from "../components/hero"
import React, { Component } from "react"
import BookingForm from "../components/booking-form"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

const PortfolioPage = () => {
  const data = useStaticQuery(graphql`
    query PortfolioQuery {
      allCloudinaryMedia(filter: { secure_url: { regex: "/portfolio/" } }) {
        edges {
          node {
            secure_url
          }
        }
      }
      livingRoomImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/livingroom-minified--sizereduce-2" }
      ) {
        secure_url
      }
    }
  `)

  const images = data.allCloudinaryMedia.edges

  return (
    <Layout>
      <SEO
        title="Portfolio"
        description="Pivot Painters - Chicago Painting Service Portfolio"
      />
      <Hero name="Portfolio" src={data.livingRoomImage.secure_url} />
      <section className="portfolioContainer">
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image.node.secure_url}
              alt="Pivot Painters - Portfolio Image"
            />
          </div>
        ))}
      </section>
      {/* <BookingForm></BookingForm> */}
    </Layout>
  )
}

export default PortfolioPage
