import React, { useState } from "react"
import svgOne from "../images/cabinets.svg"
import svgTwo from "../images/armchair.svg"
import { Link } from "gatsby"
import * as BookingFormStyles from "../styles/bookingform.module.scss"

const BookingForm = ({ isQuote, shortLeadInfo }) => {
  const [bookingState, setBookingState] = useState({
    formStep: 1,

    project_type: {
      cabinets: false,
      interior: false,
      exterior: false,
    },
    formInfo: {
      name: shortLeadInfo?.name || "",
      phone_number: shortLeadInfo?.phone_number || "",
      email: shortLeadInfo?.email || "",

      address: "",
      city: "",
      zip_code: "",
      notes: "",
      pref_method: "",
    },
  })

  const handleChangeStep = () => {
    setBookingState(prevState => ({
      ...prevState,
      formStep: prevState.formStep + 1,
    }))
  }

  const handleInputChange = e => {
    const key = e.target.name
    const value = e.target.value
    setBookingState({
      ...bookingState,
      formInfo: { ...bookingState.formInfo, [key]: value },
    })
  }

  const typeSelect = type => {
    setBookingState(prevState => ({
      ...prevState,
      project_type: {
        ...prevState.project_type,
        [type]: !prevState.project_type[type],
      },
    }))
  }

  const handleSubmit = event => {
    // event.preventDefault();
    // handleChangeStep();
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-10865682082/yugPCKGB5KgDEKLNlL0o",
      })
    }

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead", { currency: "USD" })
      }
    }

    let data = {
      name: bookingState.formInfo.name,
      phone_number: bookingState.formInfo.phone_number,
      project_type: `Interior ${bookingState.project_type.interior} | Exterior ${bookingState.project_type.exterior} | Cabinets  ${bookingState.project_type.cabinets}`,
      email: bookingState.formInfo.email,
      address_1: bookingState.formInfo.address,
      city: bookingState.formInfo.city,
      zip_code: bookingState.formInfo.zip_code,
      notes: bookingState.formInfo.notes,
      status: "Open",
      pref_method: bookingState.formInfo.pref_method,
    }

    const url = `${process.env.GATSBY_ZONUM_API_URL}/lead`

    let fetchData = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ARN:
          " arn:aws:execute-api:us-east-1:074778375650:qgdj8hao2d/*/POST/lead",
        Auth: "NONE",
      },
    }

    fetch(url, fetchData).then(function () {})
  }

  return (
    <section className={BookingFormStyles.bookingFormContainer}>
      <div className={BookingFormStyles.innerFormContainer}>
        {bookingState.formStep == 1 ? (
          <div>
            <p className={BookingFormStyles.questionTitle}>
              What Kind Of Project Would You Like Help With?
            </p>
            <div className={BookingFormStyles.typeOfJob}>
              <div
                className={`${BookingFormStyles.typeContainer} ${
                  bookingState.project_type.cabinets
                    ? BookingFormStyles.active
                    : ""
                }`}
                onClick={() => typeSelect("cabinets")}
              >
                <img
                  className={BookingFormStyles.svgs}
                  src={svgOne}
                  alt="vector of cabinets"
                ></img>
                <p>Cabinet Refinishing</p>
              </div>
              <div
                className={`${BookingFormStyles.typeContainer} ${
                  bookingState.project_type.interior
                    ? BookingFormStyles.active
                    : ""
                }`}
                onClick={() => typeSelect("interior")}
              >
                <img
                  className={BookingFormStyles.svgs}
                  src={svgTwo}
                  alt="vector of couch lamp and picture"
                ></img>
                <p>Interior Painting</p>
              </div>
              {/*<div
                className={`typeContainer ${
                  bookingState.project_type.exterior ? "active" : ""
                }`}
                onClick={() => typeSelect("exterior")}
              >
                <img
                  className="svgs"
                  src={svgThree}
                  alt="vector of house"
                ></img>
                <p>Exterior Painting</p>
              </div>*/}
            </div>
            {bookingState.project_type.exterior ||
            bookingState.project_type.interior ||
            bookingState.project_type.cabinets ? (
              <button
                onClick={handleChangeStep}
                className={BookingFormStyles.continueButton}
              >
                Next
              </button>
            ) : (
              <button disabled className={BookingFormStyles.continueButton}>
                Next
              </button>
            )}
          </div>
        ) : null}

        {bookingState.formStep === 2 ? (
          <div className={BookingFormStyles.aboutProject}>
            <p className={BookingFormStyles.questionTitle}>
              Could You Tell Us About The Project?
            </p>
            <div>
              <label className={BookingFormStyles.textInputLabel}>
                Street Address
              </label>
              <input
                type="text"
                name="address"
                value={bookingState.formInfo.address}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className={BookingFormStyles.textInputLabel}>City</label>
              <input
                type="text"
                name="city"
                value={bookingState.formInfo.city}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className={BookingFormStyles.textInputLabel}>
                Zip Code
              </label>
              <input
                type="text"
                name="zip_code"
                value={bookingState.formInfo.zip_code}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className={BookingFormStyles.textInputLabel}>
                Tell Us Details About Your Project
              </label>
              <textarea
                type="text"
                name="notes"
                value={bookingState.formInfo.notes}
                onChange={handleInputChange}
              />
            </div>
            {/* <div>
          <label>Photos Of Areas To Be Painted</label>
          <input type="file"/>
        </div> */}
            <button
              onClick={handleChangeStep}
              className={BookingFormStyles.continueButton}
            >
              Next
            </button>
          </div>
        ) : null}
        {bookingState.formStep === 3 ? (
          <div className={BookingFormStyles.aboutClient}>
            <p className={BookingFormStyles.questionTitle}>
              Could You Tell Us About You?
            </p>
            <div>
              <label className={BookingFormStyles.textInputLabel}>
                Full Name
              </label>
              <input
                type="text"
                name="name"
                value={bookingState.formInfo.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className={BookingFormStyles.textInputLabel}>
                Phone Number
              </label>
              <input
                type="text"
                name="phone_number"
                value={bookingState.formInfo.phone_number}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className={BookingFormStyles.textInputLabel}>Email</label>
              <input
                type="email"
                name="email"
                value={bookingState.formInfo.email}
                onChange={handleInputChange}
              />
            </div>
            <div className={BookingFormStyles.prefMethodContainer}>
              <label className={BookingFormStyles.headerLabel}>
                Preferred Method of Communication
              </label>
              <div className={BookingFormStyles.radioGroup}>
                <div className={BookingFormStyles.prefMethodOption}>
                  <input
                    type="radio"
                    name="pref_method"
                    value="text"
                    onChange={handleInputChange}
                  />
                  <label>Text</label>
                </div>
                <div className={BookingFormStyles.prefMethodOption}>
                  <input
                    type="radio"
                    name="pref_method"
                    value="email"
                    onChange={handleInputChange}
                  />
                  <label>Email</label>
                </div>
                <div className={BookingFormStyles.prefMethodOption}>
                  <input
                    type="radio"
                    name="pref_method"
                    value="call"
                    onChange={handleInputChange}
                  />
                  <label>Call</label>
                </div>
              </div>
            </div>
            <Link
              to="/thank-you"
              onClick={handleSubmit}
              className={BookingFormStyles.submitButton}
            >
              Start Your Project!
            </Link>
          </div>
        ) : null}
      </div>

      {/*<iframe src="https://zonumpainting.youcanbook.me/?noframe=true&skipHeaderFooter=true" id="ycbmiframezonumpainting" style={{ width: 100 + '%', height: 1000 + 'px', border: 0 + 'px', backGroundColor: 'transparent' }} frameborder="0" allowtransparency="true"></iframe> */}
    </section>
  )
}

BookingForm.propTypes = {}

export default BookingForm
